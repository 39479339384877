import axios from 'axios';

const API_VERSION = 'v1';

const API_ORIGIN = {
	development: import.meta.env.VITE_APP_BACKEND_URL || 'https://insurer-stag.foresightanalytics.ca/api',
	staging: 'https://insurer-stag.foresightanalytics.ca/api',
	production: 'https://insurer.foresightanalytics.ca/api',
}[import.meta.env.MODE];
export const API_BASE_URL = `${API_ORIGIN}/${API_VERSION}`;
export const REGEX = {
	NAME: /^[a-zA-Z ]+$/,
	EMAIL: /^\S*@?\S*$/,
	PHONE_NUMBER: /^\+1 \(\d{3}\) \d{3}-\d{4}$/,
};
export const MASK = {
	NAME: REGEX.NAME,
	EMAIL: REGEX.EMAIL,
	PHONE_NUMBER: '+1 (000) 000-0000',
	TWO_FA: '00000',
	DIGIT: '0',
};

export const AXIOS = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
});

export default {
	API_BASE_URL,
	REGEX,
	MASK,
	AXIOS,
};
