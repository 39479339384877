export default [
  {
    name: 'policy-search',
    path: '/policy-search',
    component: () => import('../views/policy-search/page.vue'),
    meta: {
      auth: true,
      topRow: true,
      title: 'Policy Search',
    },
  },
  {
    name: 'users',
    path: '/users',
    component: () => import('../views/users/page.vue'),
    meta: {
      auth: true,
      topRow: true,
      title: 'Users',
    },
  },
  {
    name: 'geo-reports',
    path: '/geo-reports',
    component: () => import('../views/geo-reports/page.vue'),
    meta: {
      auth: true,
      topRow: true,
      title: 'Geo Reports',
    },
  },
  {
    name: 'Profile',
    path: '/profile',
    component: () => import('../views/profile/page.vue'),
    meta: {
      auth: true,
      topRow: true,
      title: 'Profile',
    },
    children: [
      {
        path: 'preferences',
        component: () => import('../views/profile/preferences/page.vue'),
      },
      {
        path: 'user-details',
        component: () => import('../views/profile/user-details/page.vue'),
      },
      {
        path: 'change-password',
        component: () => import('../views/profile/change-password/page.vue'),
      },
    ],
  },
]