import auth from './auth';
import scopes from './scopes';
import users from './users';
import reports from './reports';
import misc from './misc';

export default {
    auth,
    scopes,
    users,
    reports,
    misc,
};