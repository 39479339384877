import { createStore } from 'vuex';
import { api } from '$lib';
import _ from 'lodash';

const getUser = async () => {
    const [usr, scopes] = await Promise.all([
        api.users.getCurrentUser(),
        api.users.getCurrentUserScopes(),
    ]);
    const r = usr;
    const fleets = await api.scopes.getFleets({
        scopeId: usr?.role?.scopeId,
    });
    if (scopes?.length) {
        const userScope = scopes.find((i: any) => i.scopeId === usr?.role?.scopeId);
        if (userScope && !usr.company) {
            usr.company = userScope?.scopeName;
        }
        if (userScope?.canViewScore !== null) {
            usr.canViewScore = !!userScope?.canViewScore;
        }
        r.scopes = scopes
    }
    if (fleets?.length) r.fleets = fleets;
    return r;
};

const deviceDesc = (obj: any) => {
    const name = obj.name || obj.device_name;
    const id = obj.device_id || obj.id;
    if (!name) return id;
    return name;
};

const state = {
    route: null,
    authenticated: null,
    user: null,
    selectedFleetId: null,
    devices: [],
    drivers: [],
};
const getters = {
    isGlobalScope(state: any) {
        return state.user?.role?.scopeType === 'global';
    },
    isEditorRole(state: any) {
        return state.user?.role?.roleName?.toLowerCase() === 'editor';
    },
    isFleetManagerRole(state: any) {
        return state.user?.role?.roleName?.toLowerCase() === 'fleet manager';
    },
    userScopesById(state: any) {
        return Object.fromEntries(
            (state.user?.scopes ?? []).map((i: any) => [i.scopeId, i]),
        );
    },
    fleets(state: any, getters: any) {
        let r = state.user?.fleets ?? [];
        const fleets = (getters.isGlobalScope || getters.isFleetManagerRole)
            ? api.misc.getFleets()
            : [];
        return _.uniqBy([
            ...r, ...fleets,
        ], 'id');
    },
    fleetsById(_state: any, getters: any) {
        return _.mapKeys(getters.fleets, 'id');
    },
    fleetWithId(_state: any, getters: any) {
        return (fleetId: string) => getters.fleetsById[fleetId];
    },
};
const mutations = {
    setUser(state: any, user: any) {
        state.user = user;
    },
    authenticate(state: any, user: any) {
        state.authenticated = true;
        this.commit('setUser', user);
        state.user = user;
    },
    deauthenticate(state: any) {
        state.authenticated = false;
        state.user = null;
    },
    setDevices(state: any, devices: any) {
        state.devices = devices.map((i) => ({
            ...i,
            desc: deviceDesc(i),
        }));
    },
    setDrivers(state: any, drivers: any) {
        state.drivers = drivers.map((i) => ({
            ...i,
            desc: `${i.license_number ?? ''} ${i.name ?? ''}`.trim(),
        }));
    },
};
const actions = {
    async checkAuthenticated(context: any) {
        const user = await getUser();
        if (user) {
            context.commit('authenticate', user);
        } else {
            context.commit('deauthenticate');
        }
    },
    async getDevices(context: any) {
        const devices = await api.misc.getDevices();
        context.commit('setDevices', devices);
    },
    async getDrivers(context: any) {
        const drivers = await api.misc.getDrivers();
        context.commit('setDrivers', drivers);
    },
};

export default createStore({ state, getters, mutations, actions });