import { AXIOS } from '$consts';
import policies from './policies.json';
import fleets from './fleets.json';
import devices from './devices.json';
import drivers from './drivers.json';

// TODO: we have to integrate real API and remove JSON files

export const getPolicies = async (payload: any) => {
	return new Promise((res, rej) => {
		setTimeout(() => {
			const r: any = localStorage.getItem('policies');
			if (!r) localStorage.setItem('policies', JSON.stringify(policies));
			res(JSON.parse(r ?? JSON.stringify(policies)));
		}, 1000);
	});
};

const getFleets = () => {
	const r: any = localStorage.getItem('fleets');
	if (!r) localStorage.setItem('fleets', JSON.stringify(fleets));
	return JSON.parse(r ?? '[]');
};

export const getDevices = async (payload: any) => {
	return new Promise((res, rej) => {
		setTimeout(() => {
			const r: any = localStorage.getItem('devices');
			if (!r) localStorage.setItem('devices', JSON.stringify(devices));
			res(JSON.parse(r ?? JSON.stringify(devices)));
		}, 1000);
	});
};

export const getDrivers = async (payload: any) => {
	return new Promise((res, rej) => {
		setTimeout(() => {
			const r: any = localStorage.getItem('drivers');
			if (!r) localStorage.setItem('drivers', JSON.stringify(drivers));
			res(JSON.parse(r ?? JSON.stringify(drivers)));
		}, 1000);
	});
};

export const updatePolicy = (payload: any) => {
	return new Promise((res, rej) => {
		setTimeout(() => {
			let r: any = JSON.parse(localStorage.getItem('policies') ?? '[]');
			const allDevices: any = JSON.parse(localStorage.getItem('devices') ?? '[]');
			const allDrivers: any = JSON.parse(localStorage.getItem('drivers') ?? '[]');
			r = r.map((i: any) => {
				if (payload.policy_id !== i.id) return i;
				const devices = (payload?.devices ?? i.devices).map((j: any) => ({
					id: j,
					name: allDevices.find((o) => o.id === j)?.name,
				}));
				const drivers = (payload?.drivers ?? i.drivers).map((j: any) => {
					const d = allDrivers.find((o) => o.id === j);
					return {
						id: j,
						name: d?.name,
						license_number: d?.license_number,
					};
				});
				return {
					...i,
					name: payload?.policy_name ?? i.name,
					number: payload?.policy_number ?? i.number,
					devices,
					drivers,
				};
			});
			localStorage.setItem('policies', JSON.stringify(r));
			res(true);
		}, 1000);
	});
};

export const addPolicy = (payload: any) => {
	return new Promise((res, rej) => {
		setTimeout(() => {
			const r: any = JSON.parse(localStorage.getItem('policies') ?? '[]');
			const allDevices: any = JSON.parse(localStorage.getItem('devices') ?? '[]');
			const allDrivers: any = JSON.parse(localStorage.getItem('drivers') ?? '[]');
			const devices = (payload?.devices ?? []).map((j: any) => ({
				id: j,
				name: allDevices.find((o) => o.id === j)?.name,
			}));
			const drivers = (payload?.drivers ?? []).map((j: any) => {
				const d = allDrivers.find((o) => o.id === j);
				return {
					id: j,
					name: d?.name,
					license_number: d?.license_number,
				};
			});
			r.push({
				id: Math.random(),
				name: payload?.policy_name,
				number: payload?.policy_number,
				fleet_id: payload?.fleet_id,
				devices,
				drivers,
			});
			localStorage.setItem('policies', JSON.stringify(r));
			res(true);
		}, 1000);
	});
};

export const addFleet = (payload: any) => {
	return new Promise((res, rej) => {
		setTimeout(() => {
			const r: any = JSON.parse(localStorage.getItem('fleets') ?? '[]');
			r.push({
				id: Math.random(),
				name: payload?.fleet_name,
				tsp: payload?.tsp,
				api_key: payload?.api_key,
				status: 'created',
				retiredAt: null,
				mock: true,
				users: [],
			});
			localStorage.setItem('fleets', JSON.stringify(r));
			res(true);
		}, 1000);
	});
};

export default {
	getPolicies,
	getFleets,
	getDevices,
	getDrivers,
	updatePolicy,
	addPolicy,
	addFleet,
};
