import { AXIOS } from '$consts';

export const getDevice = async (payload: any) => {
	const key = Object.keys(payload)[0];
	const value = payload[key];
	const res = await AXIOS.get(`/reports/device?${key}=${value}`)
		.catch((e: any) => e.response);
	if (res?.status !== 200) return [];
	return res.data;
};

export const getDriver = async (payload: any) => {
	const key = Object.keys(payload)[0];
	const value = payload[key];
	const res = await AXIOS.get(`/reports/driver?${key}=${value}`)
		.catch((e: any) => e.response);
	if (res?.status !== 200) return [];
	return res.data;
};

export const getPolicy = async (payload: any) => {
	const key = Object.keys(payload)[0];
	const value = payload[key];
	const res = await AXIOS.get(`/reports/policy?${key}=${value}`)
		.catch((e: any) => e.response);
	if (res?.status !== 200) return;
	return res.data;
};

export const searchOnPolicy = async (payload: any) => {
	const res = await AXIOS.get(`/reports/search?query=${payload.query}`)
		.catch((e: any) => e.response);
	if (res?.status !== 200) return;
	return res.data;
};

export default {
	getDevice,
	getDriver,
	getPolicy,
	searchOnPolicy,
};
