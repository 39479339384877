import { AXIOS } from '$consts';

export const pushPhoneVerification = async (payload: any, headers: any) => {
	const r = await AXIOS.request({
		method: 'post',
		url: '/auth/PushPhoneVerify',
		headers,
		data: {
			PhoneNumber: payload.phoneNumber,
		},
	})
		.catch((e: any) => e.response);
	return r?.status === 200;
};

export const phoneVerification = async (payload: any, headers: any) => {
	const r = await AXIOS.request({
		method: 'post',
		url: '/auth/PhoneVerify',
		headers,
		data: payload,
	})
		.catch((e: any) => e.response);
	return r?.status === 200;
};

export const login = async (payload: any) => {
	const r = await AXIOS.post('/auth/login', payload)
		.catch((e: any) => e.response);
	return r.data;
};

export const twoFactorAuthentication = async (payload: any, headers: any) => {
	const r = await AXIOS.request({
		method: 'post',
		url: '/auth/2fa',
		headers,
		data: payload,
	})
		.catch((e: any) => e.response);
	return r?.status === 200;
};

export const logout = async () => {
	const r = await AXIOS.post('/auth/logout')
		.catch((e: any) => e.response);
	return r?.status === 200;
};

export const signup = async (payload: any, headers: any) => {
	const r = await AXIOS.request({
		method: 'post',
		url: '/auth/signup',
		headers,
		data: {
			FirstName: payload.firstName,
			LastName: payload.lastName,
			PhoneNumber: payload.phoneNumber,
			Password: payload.password,
		},
	})
		.catch((e: any) => e.response);
	return r?.status === 200;
};

export const forgotPassword = async (payload: any) => {
	const r = await AXIOS.post('/auth/forgotPassword', {
		Email: payload.email,
	})
		.catch((e: any) => e.response);
	return r?.status === 200;
};

export const passwordReset = async (payload: any, headers: any) => {
	const r = await AXIOS.request({
		method: 'post',
		url: '/auth/PasswordReset',
		headers,
		data: {
			email: payload.email,
			password: payload.password,
		},
	})
		.catch((e: any) => e.response);
	return r?.status === 200;
};

export const updateCurrentUserPassword = async (payload: any) => {
	const r = await AXIOS.post('/auth/PasswordUpdate', {
		oldPassword: payload.current,
		newPassword: payload.new,
	})
		.catch((e: any) => e.response);
	return r?.status === 200;
};

export default {
	login,
	twoFactorAuthentication,
	logout,
	signup,
	pushPhoneVerification,
	phoneVerification,
	forgotPassword,
	passwordReset,
	updateCurrentUserPassword,
};
