import bus from '$bus';
import _ from 'lodash';

const isDarkMode = () => {
  if (
    window.localStorage.theme === 'dark'
    || (
        !('theme' in window.localStorage)
        && window.matchMedia('(prefers-color-scheme: dark)').matches
    )
  ) return true;
  return false;
}
const toggleDarkMode = (appearance: string) => {
  if (appearance === 'dark') localStorage.theme = 'dark';
  if (appearance === 'light') localStorage.theme = 'light';
  if (appearance === 'system-preference') localStorage.removeItem('theme');
  // On page load or when changing themes, best to add inline in `head` to avoid FOUC
  const result = isDarkMode();
  bus.emit('appearance-changed', result);
  if (result) {
    document.documentElement.classList.add('dark');
  } else {
    document.documentElement.classList.remove('dark');
  }
};

export default {
  isDarkMode,
  toggleDarkMode,
  addGoogleChartsLoaderIfNotExist() {
    const gstaticSrc = 'https://www.gstatic.com/charts/loader.js';
    if (!document.querySelector(`script[src="${gstaticSrc}"]`)) {
      const script = document.createElement('script');
      script.src = gstaticSrc;
      script.type = 'text/javascript';
      document.querySelector('head').appendChild(script);
    }
  },
  distance(meters: number, useImperial = false, precision = 1) {
    let value: any = meters / 1000;
    let unit = 'km';
    if (useImperial) {
      value = meters / 1609.344;
      unit = 'mi';
    }
    // show precision if the value was lower than 1000
    value = value.toFixed(value < 1000 ? precision : 0);
    return `${parseFloat(value).toLocaleString('en')}\xa0${unit}`;
  },
  redirect(store: any) {
    const { path, meta } = store.state.route;
    if (store.state.authenticated === null) return false;
    const res = (() => {
      // authed redirects
      if (store.state.authenticated) {
        let home = '/policy-search';
        // Non-admin users can not access to these pages
        if (!store.getters.isGlobalScope) {
         if (path.match(/^\/(groups|fleets|policy)/)) return home;
        }
        // regular redirections
        const redirects: any = {
          '/': home,
          '/login': home,
          '/signup': home,
        };
        return redirects[path];
      }
      if (path === '/') return '/login';
      if (meta.auth && !store.state.magic) return '/login';
      return undefined;
    })();
    if (res === path) return undefined; // prevent infinite loop
    return res;
  },
  exportDataToCSV(data: any[], name: any) {
    const output = data.map((i: any) => i.map((j: any) => `"${j}"`).toString()).join('\n');
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(output)}`);
    link.setAttribute('download', `${name}.csv`);
    link.click();
  },
  masks: {
    email: {
      mask: 'Z*@Z*.Z*',
      tokens: {
        Z: { pattern: /[A-Za-z0-9_\-.+]/ },
      },
    },
    deviceId: {
      mask: ['###', '####'],
    },
    artifactId: {
      mask: '######################',
    },
    phone_number: {
      mask: '+# (###) ###-####',
    },
  },
};
