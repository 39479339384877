import store from '../store';
import utils from '$utils';
import { createRouter, createWebHistory } from 'vue-router';

import AuthRoutes from './auth.ts';
import RegularRoutes from './regular.ts';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        ...AuthRoutes,
        ...RegularRoutes,
    ],
});

router.beforeEach((to, from, next) => {
    store.state.route = to;
    const prefix = to.meta.title ? `${to.meta.title} | ` : '';
    document.title = `${prefix} Insurer Portal - Foresight`;
    next(utils.redirect(store));
});

export default router;