import { AXIOS } from '$consts';
import { format } from 'date-fns';

export const getAllUsers = async () => {
	const r = await AXIOS.get('/scopes/users')
		.catch((e: any) => e.response);
	if (r?.status !== 200) return [];
	return r.data;
};

export const removeUser = async (payload: any) => {
	const r = await AXIOS.delete(`/scopes/${payload.scopeId}/users/${payload.userId}`)
		.catch((e: any) => e.response);
	return r?.status === 200;
};

export const inviteUser = async (payload: any) => {
	const r = await AXIOS.post(`/scopes/${payload.scope}/users`, {
		Email: payload.email,
		RoleName: payload.role,
	}).catch((e: any) => e.response);
	return r?.status === 200;
};

export const reinviteUser = async (payload: any) => {
	const r = await AXIOS.post(`/scopes/${payload.scope}/reinvite/users`, {
		email: payload.email,
	}).catch((e: any) => e.response);
	return r?.status === 200;
};

export const getFleets = async (payload: any) => {
	const r = await AXIOS.get(`/scopes/${payload.scopeId}/fleets`)
		.catch((e: any) => e.response);
	if (r?.status !== 200) return [];
	return r.data;
};

export const getGeoReports = async (payload: any) => {
	const fleetIdsQuery = (payload?.fleetIds ?? []).reduce((a: any, i: any) => a + '&' + 'fleetIds=' + i, '');
	const from = format(payload.from, 'yyyy-MM-dd');
	const to = format(payload.to, 'yyyy-MM-dd');
	const r = await AXIOS.get(`/scopes/${payload.scopeId}/fleets/GeoReport?start=${from}&end=${to}${fleetIdsQuery}`)
		.catch((e: any) => e.response);
	if (r?.status !== 200) return [];
	return r.data;
};

export const updateFleets = async (payload: any) => {
	const r = await AXIOS.post(`/scopes/${payload.scopeId}/fleets`, {
		fleetIds: payload?.fleetIds,
	}).catch((e: any) => e.response);
	return r?.status === 200;
};

export default {
	getAllUsers,
	removeUser,
	inviteUser,
	reinviteUser,
	getFleets,
	getGeoReports,
	updateFleets,
};
