import { AXIOS } from '$consts';

export const getCurrentUserScopes = async () => {
    const r = await AXIOS.get('/users/me/scopes').catch((e: any) => e.response);
	if (r?.status !== 200) return [];
	return r.data;
};

export const getCurrentUser = async () => {
    const r = await AXIOS.get('/users/me').catch((e: any) => e.response);
	if (r?.status !== 200) return;
	return r.data;
};

export const updateCurrentUser = async (payload: any) => {
	const r = await AXIOS.put('/users/me', payload)
		.catch((e: any) => e.response);
	return r?.status === 200;
};

export default {
	getCurrentUserScopes,
    getCurrentUser,
	updateCurrentUser,
};