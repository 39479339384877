export default [
    {
        name: 'Login',
        path: '/login',
        component: () => import('../views/login/page.vue'),
        meta: {
            auth: false,
            topRow: false,
            title: 'Login',
        },
    },
    {
        name: 'Signup',
        path: '/signup',
        component: () => import('../views/signup/page.vue'),
        meta: {
            auth: false,
            topRow: false,
            title: 'Signup',
        },
    },
    {
        name: 'Forgot Password',
        path: '/forgot-password',
        component: () => import('../views/forgot-password/page.vue'),
        meta: {
            auth: false,
            topRow: false,
            title: 'Forgot Password',
        },
    },
    {
        name: 'Password Reset',
        path: '/password-reset',
        component: () => import('../views/password-reset/page.vue'),
        meta: {
            auth: false,
            topRow: false,
            title: 'Password Reset',
        },
    },
]